import cx from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { t } from 'src/assets/translations';

export type FilterType =
  | 'FILTER_OPTION_NEWEST'
  | 'FILTER_OPTION_RANDOM'
  | 'FILTER_OPTION_BEST'
  | 'FILTER_OPTION_UNDER_20'
  | 'FILTER_OPTION_UNDER_100';

interface Props {
  onFilterChanged: (filter: FilterType) => void;
}

interface ProductFilter {
  label: string;
  id: FilterType;
}

const filters: ProductFilter[] = [
  {
    id: 'FILTER_OPTION_NEWEST',
    label: t('newestProducts'),
  },
  {
    id: 'FILTER_OPTION_RANDOM',
    label: t('random'),
  },
  {
    id: 'FILTER_OPTION_BEST',
    label: t('bestProducts'),
  },
  {
    id: 'FILTER_OPTION_UNDER_20',
    label: t('productsUnder20'),
  },
  {
    id: 'FILTER_OPTION_UNDER_100',
    label: t('productsUnder100'),
  },
];

// Empirical measurement for the current order of filters
// change this when the labels or order of filters change
const INITIAL_THUMB_WIDTH = 91.06;

const ProductFilter = ({ onFilterChanged }: Props) => {
  const [activeFilter, setActiveFilter] = useState(0);
  const [thumbLeft, setThumbLeft] = useState(0);
  const [thumbWidth, setThumbWidth] = useState(INITIAL_THUMB_WIDTH);
  // prevents animation on mount
  const [thumbAnimation, setThumbAnimation] = useState(false);
  const buttonRefs = useRef<HTMLButtonElement[]>([]);

  useEffect(() => {
    if (thumbWidth) {
      setTimeout(() => {
        setThumbAnimation(true), 500;
      });
    }
  }, [thumbWidth]);

  // useEffect(() => {
  //   const filterFn = filters[activeFilter].filter;
  //   const filtered = filterFn(allProducts);
  //   if (nfswEnabled) {
  //     onFilterChanged(filtered);
  //     return;
  //   }

  //   onFilterChanged(_.filter(filtered, 'safeForWork'));
  // }, [nfswEnabled, activeFilter, allProducts, randomSeed]);

  useEffect(() => {
    onFilterChanged(filters[activeFilter].id);
  }, [activeFilter]);

  return (
    <div
      className="relative flex flex-no-wrap mb-8 overflow-x-scroll scrollbar-hidden"
      style={{ maxWidth: '100vw' }}
    >
      {/* moving thumb */}
      <div
        className={cx('absolute top-0 bottom-0 z-0', {
          'transition-all duration-200': thumbAnimation,
        })}
        style={{ left: thumbLeft, width: thumbWidth }}
      >
        <div className="w-full h-full rounded-full bg-x-dark-blue" />
      </div>
      {/* buttons */}
      {filters.map((filter, i) => (
        <button
          className={cx(
            'px-4 block z-10  select-none whitespace-no-wrap transition-colors duration-200',
            {
              'text-white': activeFilter === i,
            }
          )}
          key={filter.label}
          onClick={() => {
            setThumbLeft(buttonRefs.current[i].offsetLeft);
            setThumbWidth(buttonRefs.current[i].getBoundingClientRect().width);
            setActiveFilter(i);
          }}
          ref={(ref) => {
            if (ref) {
              if (i === 0 && !buttonRefs.current[i]) {
                setThumbWidth(ref.offsetWidth);
              }
              buttonRefs.current[i] = ref;
            }
          }}
        >
          {filter.label}
        </button>
      ))}
      <div className="flex flex-1" />
      {/* NFSW filter */}
      {/* <div className="flex items-center">
        <span className="pl-4 mb-1">{t('nfsw')}</span>
        <div className="pl-2">
          <NFSWSwitch checked={nfswEnabled} onChange={setNfswEnabled} />
        </div>
      </div> */}
    </div>
  );
};

export default ProductFilter;
