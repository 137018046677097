import cx from 'classnames';
import { Link } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import React, { ReactElement } from 'react';
import ReactMarkdown from 'react-markdown';
import { COLOR_GRAY } from 'src/constants';
import { useStaticContext } from 'src/hooks/useStaticContext';
import { getFluidImage } from 'src/util/get-fluid-image';
import {
  ICustomGiftlist,
  ICustomGiftlistWrapper,
} from 'typings/generated/contentful';

import ListTag from './tag';

interface Props {
  giftList: ICustomGiftlist;
  expanded?: boolean;
  assetWidth: number;
}

function ListCard({ giftList, assetWidth, expanded }: Props): ReactElement {
  const context = useStaticContext();
  const giftListWrapper: ICustomGiftlistWrapper = context.customGiftlistWrapper;

  return (
    <div>
      <div
        className={cx(
          'relative flex flex-col justify-end w-full p-4 overflow-hidden rounded border-white border',
          {
            'h-40': !expanded,
            'h-56': expanded,
          }
        )}
      >
        <Link
          aria-label={giftList.fields.title}
          to={`/${giftListWrapper.fields.slug}/${giftList.fields.slug}`}
        >
          {/* image */}
          {giftList.fields.heroImage && (
            <GatsbyImage
              backgroundColor={COLOR_GRAY}
              className="top-0 bottom-0 left-0 w-full duration-200 transform hover:scale-105"
              fadeIn={false}
              fluid={getFluidImage(giftList.fields.heroImage, {
                w: assetWidth,
              })}
              style={{ position: 'absolute' }}
            />
          )}
        </Link>
        {/* semi-black overlay */}
        <div className="absolute top-0 bottom-0 left-0 right-0 bg-black bg-opacity-25 pointer-events-none" />
        <div
          className={cx(
            'relative pointer-events-none w-full h-full flex flex-col'
          )}
        >
          {/* tag */}
          <div className="flex flex-1">
            {giftList.fields.tags && giftList.fields.tags.length && (
              <ListTag tag={giftList.fields.tags[0]} />
            )}
          </div>
          <div className="flex">
            {/* list name */}
            <div
              className={cx(
                'p-2 text-sm font-bold leading-tight text-center text-white bg-white bg-opacity-50 rounded',
                { 'text-base': expanded }
              )}
            >
              {giftList.fields.title?.replace(
                giftListWrapper.fields.prefix || '',
                ''
              )}
            </div>
          </div>
        </div>
      </div>
      {expanded && (
        <div className="py-4 md:text-sm md:leading-tight">
          <div className="first-letter-big">
            <span className="line-clamp-5">
              <ReactMarkdown
                className="text-justify markdown"
                linkTarget="_blank"
                source={giftList.fields.description}
              />
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

export default ListCard;
