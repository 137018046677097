import { merge } from 'lodash';
import React, { useMemo, useState } from 'react';
import DesktopComponent from 'src/hocs/desktop-component';
import MobileComponent from 'src/hocs/mobile-component';
import { usePagination } from 'src/hooks/usePagination';
import { bestQuery, newestQuery, priceQuery } from 'src/queries';
import { LinearPaginator, RandomPaginator } from 'src/util/paginator';
import { ICustomGiftlist, IProduct } from 'typings/generated/contentful';

import LoadingIndicator from '../widget/loading-indicator';
import ProductFilter, { FilterType } from '../widget/product-filter';
import FeedProductListDesktop from './list-feed.desktop';
import FeedProductListMobile from './list-feed.mobile';

interface Props {
  initalPage?: IProduct[];
  giftLists: ICustomGiftlist[];
  query: object;
  renderHeader?: Function;
  filter: (size: number) => boolean;
}

type QueryType = {
  site: {
    buildTime: string;
  };
};

const linearPaginator = new LinearPaginator();

const randomPaginator = new RandomPaginator();

const FeedProductList = ({
  initalPage = [],
  giftLists,
  query,
  renderHeader,
  filter,
}: Props) => {
  const [activeFilter, setActiveFilter] = useState<FilterType>(
    'FILTER_OPTION_NEWEST'
  );

  let price;
  if (
    activeFilter === 'FILTER_OPTION_UNDER_20' ||
    activeFilter === 'FILTER_OPTION_UNDER_100'
  ) {
    price = activeFilter === 'FILTER_OPTION_UNDER_20' ? 20 : 100;
  }

  const { page, total, seeding, hasMore, loadNextPage } = usePagination({
    paginator:
      activeFilter === 'FILTER_OPTION_RANDOM'
        ? randomPaginator
        : linearPaginator,
    query: merge(
      {},
      query,
      activeFilter === 'FILTER_OPTION_BEST' && bestQuery(),
      activeFilter === 'FILTER_OPTION_NEWEST' && newestQuery(),
      priceQuery(price)
    ),
  });

  const displayPage = useMemo(() => initalPage.concat(page), [
    initalPage,
    page,
  ]);

  return (
    <>
      {renderHeader && renderHeader(total)}
      {filter && filter(total) && (
        <ProductFilter onFilterChanged={setActiveFilter} />
      )}
      {seeding && <LoadingIndicator />}
      <MobileComponent>
        <FeedProductListMobile
          giftLists={giftLists}
          hasMore={hasMore}
          loadMore={loadNextPage}
          products={displayPage}
        />
      </MobileComponent>
      <DesktopComponent>
        <FeedProductListDesktop
          hasMore={hasMore}
          loadMore={loadNextPage}
          products={displayPage}
        />
      </DesktopComponent>
    </>
  );
};

export default FeedProductList;
