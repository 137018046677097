import _ from 'lodash';
import React, {
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import Icon1 from 'src/assets/img/ic_1.svg';
import Icon2 from 'src/assets/img/ic_2.svg';
import ContentfulRichText from 'src/components/misc/contentful-rich-text';
import WideContainer from 'src/components/widget/wide-container';
import { COOKIE_KEY_NEWSLETTER_FORM_SHOWN } from 'src/constants';
import DesktopComponent from 'src/hocs/desktop-component';
import MobileComponent from 'src/hocs/mobile-component';
import { useStaticContext } from 'src/hooks/useStaticContext';
import useTranslation from 'src/hooks/useTranslation';
import { IMiscStaticContent } from 'typings/generated/contentful';
import Cookies from 'universal-cookie';

const TITLE_LINES = 2;

const NewsletterForm = () => {
  const t = useTranslation();
  const { miscStaticContent } = useStaticContext();
  const form: IMiscStaticContent = miscStaticContent?.find(
    (content) => content.sys.id === '2A5J9b6mILxlOaqcaQxju0'
  );
  const success: IMiscStaticContent = miscStaticContent?.find(
    (content) => content.sys.id === '1bXHqGFrB2xN3sdY17YdtD'
  );
  const failure: IMiscStaticContent = miscStaticContent?.find(
    (content) => content.sys.id === '5Z5pXDxifpQ4gpVL36eTtc'
  );
  const text = form.fields.title || '';
  const lines = _.chunk(text.split(' '), TITLE_LINES);
  const [submitSuccess, setSubmitSuccess] = useState<true | false | null>(null);
  const [submitProgress, setSubmitProgress] = useState(false);
  const [showNewsletterForm, setShowNewsletterForm] = useState(false);
  const cookies = useRef(new Cookies()).current;

  useEffect(() => {
    const shown = cookies.get(COOKIE_KEY_NEWSLETTER_FORM_SHOWN);
    setShowNewsletterForm(!shown);
    cookies.set(COOKIE_KEY_NEWSLETTER_FORM_SHOWN, true, { path: '/' }); // omit expires - session cookie
  }, []);

  const handleSubmit = useCallback((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const email = (document.getElementById('newsletter_form__email') as any)
      .value;
    setSubmitProgress(true);
    fetch(process.env.GATSBY_NEWSLETTER_SUBSCRIBER_URL as string, {
      method: 'post',
      body: JSON.stringify({
        email,
      }),
    })
      .then((res) => res.json())
      .then(({ success }) => setSubmitSuccess(success))
      .catch(() => setSubmitSuccess(false))
      .finally(() => setSubmitProgress(false));
  }, []);

  const renderSuccess = useCallback(
    () => (
      <div className="flex flex-col items-center p-8 space-y-8 tracking-wider text-center text-white bg-x-green-neon">
        <div className="px-16 py-2 bg-white rounded text-x-green-neon">
          <div className="text-2xl font-bold font-display">
            {success.fields.title}
          </div>
        </div>
        <div className="space-y-4 text-left md:w-3/5">
          <div className="flex flex-row space-x-4">
            <div className="w-8">
              <Icon1 />
            </div>
            <div className="text-xl font-light font-display">
              <ContentfulRichText document={success.fields.description} />
            </div>
          </div>
          <div className="flex flex-row space-x-4">
            <div className="w-8">
              <Icon2 />
            </div>
            <div className="text-xl font-light font-display">
              <ContentfulRichText document={success.fields.description2} />
            </div>
          </div>
        </div>
      </div>
    ),
    []
  );

  const renderFailure = useCallback(
    () => (
      <div className="flex flex-col p-4 tracking-wider text-center text-white bg-x-red">
        <div className="text-2xl font-bold font-display">
          {failure.fields.title}
        </div>
        <div className="text-xl font-light font-display">
          <ContentfulRichText document={failure.fields.description} />
        </div>
      </div>
    ),
    []
  );

  const renderForm = useCallback(
    () => (
      <div className="flex flex-col items-center w-full py-8 bg-x-gray md:space-y-0 md:flex-row ">
        <div className="px-8 md:w-3/5">
          <div className="text-4xl text-center md:text-4xl md:py-0 title md:text-left">
            <MobileComponent>
              {_.map(lines, (line, i, list) => (
                <>
                  <span>{line.join(' ')}</span>
                  {i < list.length - 1 && <br />}
                </>
              ))}
            </MobileComponent>
            <DesktopComponent>{text}</DesktopComponent>
          </div>
          <p className="py-8 text-base md:py-2">
            <ContentfulRichText document={form.fields.description} />
          </p>
        </div>
        <div className="w-full px-8 md:w-2/5">
          <form className="space-y-2" method="post" onSubmit={handleSubmit}>
            <input
              className="w-full px-2 py-2 rounded"
              id="newsletter_form__email"
              name="email"
              placeholder={t('yourEmail')}
              required
              type="email"
            />
            <button
              className="w-full text-xl btn btn-yellow btn-tall"
              disabled={submitProgress}
              type="submit"
            >
              {t('signUpNow')}
            </button>
          </form>
        </div>
      </div>
    ),
    []
  );

  if (!showNewsletterForm) {
    return null;
  }

  return (
    <WideContainer className="md:mt-20">
      {submitSuccess !== true && renderForm()}
      {submitSuccess === true && renderSuccess()}
      {submitSuccess === false && renderFailure()}
    </WideContainer>
  );
};

export default NewsletterForm;
