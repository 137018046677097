import _ from 'lodash';
import React, { Fragment, useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import MerchantNotice from 'src/components/misc/merchant-notice';
import CardFeedMobile from 'src/components/product/card.feed.mobile';
import GiftListChunk from 'src/components/widget/gift-list-chunk';
import LoadingIndicator from 'src/components/widget/loading-indicator';
import { PAGE_SIZE } from 'src/hooks/usePagination';
import { ICustomGiftlist, IProduct } from 'typings/generated/contentful';

interface Props {
  products: IProduct[];
  giftLists: ICustomGiftlist[];
  hasMore: boolean;
  loadMore: Function;
}

const GIFT_LISTS_PER_SECTION = 4;
const GIFT_LIST_SPACING = 6; // Show a gift list after each X products

const FeedProductListMobile = ({
  products,
  giftLists,
  hasMore,
  loadMore,
}: Props) => {
  const giftListChunks = useMemo(
    () =>
      _.chunk(giftLists, GIFT_LISTS_PER_SECTION).filter(
        (list) => _.size(list) === GIFT_LISTS_PER_SECTION
      ),
    [giftLists]
  );
  return (
    <InfiniteScroll
      hasMore={hasMore}
      loadMore={loadMore}
      loader={<LoadingIndicator key="loading" />}
      pageStart={0}
    >
      {products.map((p, i) => {
        const chunkIndex = Math.floor(i / GIFT_LIST_SPACING) - 1;
        const showChunk =
          i > 0 &&
          i % GIFT_LIST_SPACING === 0 &&
          chunkIndex < giftListChunks.length;
        return (
          <Fragment key={p.sys.id}>
            {/* spacer */}
            {i > 0 && <div className="h-4" />}
            {/* card */}
            <div className="flex justify-center">
              <CardFeedMobile product={p} />
            </div>
            {/* merchant notice */}
            {i === PAGE_SIZE - 1 && <MerchantNotice />}
            {/* gift lists */}
            {showChunk && <GiftListChunk chunk={giftListChunks[chunkIndex]} />}
          </Fragment>
        );
      })}
    </InfiniteScroll>
  );
};

export default FeedProductListMobile;
