import _ from 'lodash';
import React, { ReactElement } from 'react';
import ListCard from 'src/components/gift-list/list-card';
import { useStaticContext } from 'src/hooks/useStaticContext';
import hashCode from 'src/util/hash-code';
import {
  ICustomGiftlist,
  ICustomGiftlistWrapper,
} from 'typings/generated/contentful';

import WideContainer from './wide-container';

interface Props {
  chunk: ICustomGiftlist[];
}

const gradients = [
  // [red-500, red-700]
  ['#F56565', '#C53030'],
  // [orange-500, orange-700]
  ['#ED8936', '#C05621'],
  // [blue-500, blue-700]
  ['#4299E1', '#2B6CB0'],
  // [yellow-500, yellow-700]
  ['#ECC94B', '#B7791F'],
  // [purple-500, purple-700]
  ['#9F7AEA', '#6B46C1'],
];

function GiftListChunk({ chunk }: Props): ReactElement {
  const ids = _.map(chunk, 'id').join(',');
  const gradient = gradients[hashCode(ids) % gradients.length];
  const background = `linear-gradient(90deg, ${gradient.join(', ')})`;
  const context = useStaticContext();
  const giftListWrapper: ICustomGiftlistWrapper = context.customGiftlistWrapper;

  return (
    <WideContainer>
      <div className="px-12 py-8 mt-4" style={{ background }}>
        <div className="text-xl text-white title">
          {giftListWrapper.fields.title}
        </div>
        <div className="grid grid-cols-2 gap-4 mt-4">
          {chunk.map((giftList) => (
            <ListCard
              assetWidth={335}
              giftList={giftList}
              key={giftList.sys.id}
            />
          ))}
        </div>
      </div>
    </WideContainer>
  );
}

export default GiftListChunk;
