import { chain, merge } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import Layout from 'src/components/layout/layout';
import FeedProductList from 'src/components/list/list-feed';
import SEO from 'src/components/misc/seo';
import CardDetail from 'src/components/product/card.detail';
import CardDetailMobile from 'src/components/product/card.detail.mobile';
import Container from 'src/components/widget/container';
import LoadingIndicator from 'src/components/widget/loading-indicator';
import NewsletterForm from 'src/components/widget/newsletter-form';
import DesktopComponent from 'src/hocs/desktop-component';
import MobileComponent from 'src/hocs/mobile-component';
import useTranslation from 'src/hooks/useTranslation';
import {
  categoryProductsQuery,
  excludeIdQuery,
  productsQuery,
} from 'src/queries';
import { contentfulClient } from 'src/util/contentful-client';
import { StaticContextType } from 'typings/custom';
import { IProduct } from 'typings/generated/contentful';

interface Props {
  id: string;
  pageContext: {
    reactContext: StaticContextType;
    product: IProduct | undefined;
    similarProducts: IProduct[];
  };
}

const ProductDetailPage = (props: Props) => {
  const {
    id,
    pageContext: { product: staticProduct, reactContext },
  } = props;
  const t = useTranslation();
  const [product, setProduct] = useState<IProduct | undefined>(staticProduct);
  const [similarProducts, setSimilarProducts] = useState<IProduct[] | null>(
    null
  );

  useEffect(() => {
    console.log({ product });
    if (!product) {
      contentfulClient
        .getEntries({
          // eslint-disable-next-line @typescript-eslint/camelcase
          content_type: 'product',
          'fields.slug[in]': id,
        })
        .then((entries) => {
          console.log({ entries });
          if (entries.total !== 1) {
            window.location.href = '/';
          } else {
            setProduct(entries.items[0] as IProduct);
          }
        });
    }
  }, [id, product]);

  useEffect(() => {
    if (!product) {
      return;
    }
    Promise.all(
      (product.fields.subcategories || []).map((subcategory) =>
        contentfulClient.getEntries(
          merge(
            {},
            productsQuery(),
            excludeIdQuery(product.sys.id),
            categoryProductsQuery(subcategory.sys.id)
          )
        )
      )
    )
      .then((pages) => {
        setSimilarProducts(
          chain(pages)
            .map((page) => page.items as IProduct[])
            .flatten()
            .value()
        );
      })
      .catch(() => setSimilarProducts([]));
  }, [product]);

  const renderContent = useCallback(() => {
    if (!product) {
      return <LoadingIndicator />;
    }
    return (
      <>
        <SEO
          description={product.fields.description}
          image={product.fields.media?.[0].fields.file.url}
          title={product.fields.title}
        />
        <div className="flex flex-col items-center">
          <Container className="pb-8" loose>
            <MobileComponent>
              <CardDetailMobile product={product} />
            </MobileComponent>
            <DesktopComponent>
              <CardDetail product={product} />
            </DesktopComponent>
          </Container>
          <Container>
            <NewsletterForm />
          </Container>
          <Container loose>
            <div className="py-12 text-4xl text-center md:mt-24 md:text-5xl title">
              {t('similarProducts')}
            </div>
            {similarProducts !== null && (
              <FeedProductList
                filter={() => false}
                giftLists={[]}
                initalPage={similarProducts}
                query={merge(
                  {},
                  productsQuery(),
                  excludeIdQuery(product.sys.id)
                )}
              />
            )}
          </Container>
        </div>
      </>
    );
  }, [product, similarProducts]);

  return <Layout context={reactContext}>{renderContent()}</Layout>;
};

export default ProductDetailPage;
