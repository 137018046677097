import React from 'react';
import PriceDisplay from 'src/components/widget/price-display';
import ProductImage from 'src/components/widget/product-image';
import useTranslation from 'src/hooks/useTranslation';
import { relativeSlug } from 'src/util/product-slug';
import { IProduct } from 'typings/generated/contentful';

interface Props {
  product: IProduct;
}

const CardFeedMobile = (props: Props) => {
  const { product } = props;
  const t = useTranslation();
  const href = relativeSlug(product.fields);

  return (
    <div className="flex flex-col w-full h-full max-w-sm">
      <a aria-label={product.fields.title} href={href}>
        <div className="relative flex items-center justify-center px-4 py-2 text-2xl leading-8 text-center title">
          {product.fields.title}
        </div>
      </a>
      <div className="relative w-full overflow-hidden">
        <a aria-label={product.fields.title} href={href}>
          <ProductImage product={product} />
        </a>
        <div className="absolute top-0 bottom-0 left-0 right-0 pointer-events-none gradient-black-transparent-b" />
        <div className="absolute bottom-0 left-0 right-0 flex items-center justify-between p-4 font-bold text-white">
          <PriceDisplay icon={false} price={product.fields.price} />
          <a
            aria-label={product.fields.title}
            className="btn btn-yellow btn-tall"
            href={href}
          >
            {t('moreInfo')}
          </a>
        </div>
      </div>
    </div>
  );
};

export default CardFeedMobile;
