/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactElement } from 'react';
import { IGiftListTags } from 'typings/generated/contentful';

interface Props {
  tag: IGiftListTags;
}

const FONT_MAP = {
  '🏖': '#5FD8D0',
  '🍆': '#BE8CBB',
  '🏠': '#E7BF7B',
  '🤣': '#3BC8FE',
  '🛠': '#7C8188',
  '🤓': '#A76707',
  '🍺': '#FFC431',
  '😳': '#EE7D17',
  '☠️': '#B9BBBC',
  default: '#000',
};

function ListTagOld({ tag }: Props): ReactElement {
  const { name } = tag.fields;
  const emoji = name[name.length - 2];
  const color = FONT_MAP[emoji] || FONT_MAP.default;
  return (
    <div className="inline-flex py-2">
      <div
        className="flex-grow-0 px-2 bg-white rounded title"
        style={{ color }}
      >
        <span className="leading-normal">{name}</span>
      </div>
    </div>
  );
}

const ListTag = (props: Props) => null;

export default ListTag;
