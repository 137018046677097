import React, { ReactElement } from 'react';
import ReactMarkdown from 'react-markdown';
import IconCart from 'src/assets/img/ic_cart.svg';
import AnchorLink from 'src/components/widget/anchor-link';
import MediaGallery from 'src/components/widget/media-gallery';
import useIsClient from 'src/hooks/useIsClient';
import useTranslation from 'src/hooks/useTranslation';
import { getShareLinks } from 'src/util/get-share-links';
import { priceFormat } from 'src/util/price-format';
import { absoluteSlug } from 'src/util/product-slug';
import { updateProductScore } from 'src/util/update-product-score';
import { IProduct } from 'typings/generated/contentful';

interface Props {
  product: IProduct;
}

function CardDetail({ product }: Props): ReactElement {
  const {
    fields: { title, price, link },
  } = product;
  const t = useTranslation();
  const isClient = useIsClient();
  const href = absoluteSlug(product.fields, isClient);

  const shareLinks = getShareLinks(product, href);

  return (
    <div className="flex w-full space-x-16 ">
      <div className="w-1/2">
        <MediaGallery product={product} />
      </div>
      <div className="flex flex-col w-1/2">
        <div className="text-4xl title">{title}</div>
        <div className="flex py-4 space-x-16">
          <div className="flex flex-col space-y-2">
            <div>{t('price')}</div>
            <div className="text-2xl">{priceFormat(price, true)}</div>
          </div>
          <div className="flex flex-col space-y-2">
            <div>{t('shareWithSocialMedia')}</div>
            <div className="flex space-x-4">
              {shareLinks.map(({ icon: Icon, link, ariaLabel }, index) => (
                <a
                  aria-label={ariaLabel}
                  className="h-8 share-popup"
                  href={link}
                  key={index.toString()}
                >
                  <Icon className="h-full" />
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className="py-2">
          <ReactMarkdown
            className="text-justify markdown"
            linkTarget="_blank"
            source={product.fields.description}
          />
        </div>
        <div className="flex justify-center py-8">
          <AnchorLink
            className="flex items-center justify-center w-64 space-x-4 btn btn-yellow btn-tall"
            href={link}
            onClick={() => updateProductScore(product)}
          >
            <IconCart className="w-6 h-6" />
            <span>{t('toTheProduct')}</span>
          </AnchorLink>
        </div>
      </div>
    </div>
  );
}

export default CardDetail;
