import React, { useCallback, useState } from 'react';
import { getYoutubeId } from 'src/util/get-youtube-id';
import { IProduct } from 'typings/generated/contentful';
import ProductImage from './product-image';

type Props = {
  product: IProduct;
};

const MediaGallery = ({ product }: Props) => {
  const {
    fields: { media, videoLink },
  } = product;
  const youtubeId = product.fields.videoLink ? getYoutubeId(videoLink) : null;
  const [previewIndex, setPreviewIndex] = useState(0);

  const renderLargePreview = useCallback(
    () => (
      <div
        className="cursor-pointer"
        key="large-preview"
        onClick={() => setPreviewIndex(0)}
      >
        <ProductImage mediaIndex={previewIndex} product={product} />
      </div>
    ),
    [previewIndex, product]
  );

  const renderFluid = useCallback(
    (index) => (
      <div
        className="cursor-pointer"
        key="fluid"
        onClick={() => setPreviewIndex(previewIndex === index ? 0 : index)}
      >
        <ProductImage mediaIndex={index} product={product} />
      </div>
    ),
    [previewIndex, product]
  );

  // const renderVideo = useCallback(
  //   (index) => (
  //     <video
  //       className="rounded"
  //       controls
  //       controlsList="nodownload noremoteplayback"
  //       key="video"
  //       loop
  //     >
  //       <source src={product.fields.media?.[index].fields.file.url} />
  //     </video>
  //   ),
  //   [product]
  // );

  const renderYoutubeEmbed = useCallback(
    () =>
      youtubeId && (
        <iframe
          className="self-center w-full"
          frameBorder="0"
          key="youtube-embed"
          src={`//www.youtube.com/embed/${youtubeId}`}
        />
      ),
    [youtubeId]
  );

  return (
    <div className="grid grid-cols-2 gap-4">
      <div className="col-span-2">{renderLargePreview()}</div>
      {(media || []).slice(1).map((productMedia, index) => (
        <div key={productMedia.sys.id}>{renderFluid(index + 1)}</div>
      ))}
      {renderYoutubeEmbed()}
    </div>
  );
};

export default MediaGallery;
