import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import _ from 'lodash';
import React, { useCallback } from 'react';
import ReactMarkdown from 'react-markdown';
import Slider from 'react-slick';
import IconCart from 'src/assets/img/ic_cart.svg';
import IconWhatsapp from 'src/assets/img/ic_whatsapp.svg';
import AnchorLink from 'src/components/widget/anchor-link';
import { COLOR_WHATSAPP_GREEN } from 'src/constants';
import useIsClient from 'src/hooks/useIsClient';
import useTranslation from 'src/hooks/useTranslation';
import { getYoutubeId } from 'src/util/get-youtube-id';
import { priceFormat } from 'src/util/price-format';
import { absoluteSlug } from 'src/util/product-slug';
import { getWhatsappLink } from 'src/util/social-links';
import { updateProductScore } from 'src/util/update-product-score';
import { IProduct } from 'typings/generated/contentful';

import ProductImage from '../widget/product-image';

interface Props {
  product: IProduct;
}

const CardDetailMobile = ({ product }: Props) => {
  const t = useTranslation();
  const isClient = useIsClient();
  const href = absoluteSlug(product.fields, isClient);
  const youtubeId = product.fields.videoLink
    ? getYoutubeId(product.fields.videoLink)
    : null;

  const renderMedia = useCallback((product: IProduct) => {
    return (
      product.fields.media?.map((v, index) => (
        <ProductImage
          key={index.toString()}
          mediaIndex={index}
          product={product}
        />
      )) || []
    );
  }, []);

  const slidesCount =
    Number(product.fields.media?.length) + Number(!!youtubeId);

  return (
    <div>
      <Slider dots={slidesCount > 1} slidesToScroll={1} slidesToShow={1}>
        {_.compact([
          ...renderMedia(product),
          !!youtubeId && (
            <div className="relative flex items-center w-full h-full bg-white">
              <iframe
                className="w-full h-72"
                frameBorder="0"
                src={`//www.youtube.com/embed/${youtubeId}`}
              />
              {/* Swipe areas */}
              <div className="absolute top-0 bottom-0 left-0 z-10 w-1/3 my-16" />
              <div className="absolute top-0 bottom-0 right-0 z-10 w-1/3 my-16" />
            </div>
          ),
        ])}
      </Slider>
      {slidesCount > 1 && <div className="h-4" />}
      <div className="relative flex items-center justify-center px-4 pt-4 text-3xl leading-8 text-center title ">
        {product.fields.title}
      </div>
      <div className="w-full px-2 py-4">
        {
          <ReactMarkdown
            className="text-justify markdown"
            linkTarget="_blank"
            source={product.fields.description}
          />
        }
      </div>
      <div className="flex flex-col items-center justify-between w-full px-2 py-4 space-y-2 ">
        <AnchorLink
          className="flex items-center justify-center w-full space-x-4 btn btn-yellow btn-tall"
          href={product.fields.link}
          onClick={() => updateProductScore(product)}
        >
          <IconCart className="w-6 h-6" />
          <span>
            {t('toTheProduct')} {priceFormat(product.fields.price, true)}
          </span>
        </AnchorLink>
        <AnchorLink
          className="flex items-center justify-center w-full space-x-4 btn btn-tall"
          href={getWhatsappLink({
            pageLink: href,
            title: product.fields.title,
          })}
          style={{
            background: COLOR_WHATSAPP_GREEN,
          }}
        >
          <IconWhatsapp className="w-6 h-6" />
          <span>{t('shareWithWhatsApp')}</span>{' '}
        </AnchorLink>
      </div>
    </div>
  );
};

export default CardDetailMobile;
